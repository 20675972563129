// extracted by mini-css-extract-plugin
export var clearfix = "home-module--clearfix--1UmO_";
export var srOnly = "home-module--sr-only--1jAw3";
export var wrapper = "home-module--wrapper--3gA-_";
export var buttonHeader = "home-module--button-header--3UB5U";
export var scrollTop = "home-module--scroll-top--ll7jd";
export var fadeIn = "home-module--fadeIn--wcnLE";
export var mainContainer = "home-module--main-container--2B8J1";
export var main = "home-module--main--rZXkx";
export var paraHeader = "home-module--para-header--26fHw";
export var logo = "home-module--logo--1zV7i";
export var logoContainer = "home-module--logo-container--3rWvl";
export var line = "home-module--line--AOhYK";
export var headerContainer = "home-module--header-container--35hsW";
export var header = "home-module--header--3dFFH";
export var rightContainer = "home-module--right-container--3FucF";
export var socialContainer = "home-module--social-container--2GS2D";
export var headerInsta = "home-module--header-insta--34CTU";
export var navContainer = "home-module--nav-container--2RbFx";
export var nav = "home-module--nav--dbZy1";
export var active = "home-module--active--2iamA";
export var bioContainer = "home-module--bio-container--39ZN7";
export var innerContainer = "home-module--inner-container--6NGKL";
export var bioText = "home-module--bio-text--3IbQw";
export var button = "home-module--button--i7aPY";
export var bioImage = "home-module--bio-image--3pc8G";
export var footer = "home-module--footer--FHWY7";
export var footerPara = "home-module--footer-para--3Evi9";
export var socialList = "home-module--social-list--1LFf5";
export var socialLink = "home-module--social-link--2-9N1";
export var socialLinkHeader = "home-module--social-link-header--K4Q0F";
export var errorSection = "home-module--error-section--1SMq_";
export var mobileHero = "home-module--mobile-hero--3VFYR";